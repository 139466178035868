<template>
    <div>
        <member-detail-component :member-type="'curationRequest'" :memberKey="curatorKey"></member-detail-component>
        <!--        <div class="ifmf_sub"><span>Nickname</span>{{$t('s_c_request')}}</div>-->
        <div class="ifmf_sub_msg">{{$t('c_s_msg')}}</div>
        <div class="ifmf_check_wrap pt10">
            <el-checkbox v-model="termsCheck" @change="changeCheck"></el-checkbox>
            <span class="item_text_span ml10" v-html="$t('apply_curation_text1')"></span>
        </div>
        <item-list-component :item-type="'curationRequest'" :memberKey="UserInfo.mb_no"></item-list-component>
    </div>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import TermsModal from "@/components/modal/TermsModal";
import curationValidator from "@/mixins/validators/curationValidator";
import AuthMixins from "@/mixins/AuthMixins";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "CurationWriteItemsLayout",
    mixins: [alertMixins, curationValidator, AuthMixins],
    components: {
        MemberDetailComponent,
        ItemListComponent,
        TermsModal
    },
    inject: ['curationWriteSetData'],
    provide() {
        return{
            setSelectItem: this.setSelectItem,

        }
    },
    props: {
        curationData: {default: () => {}},
    },
    data() {
        return{
            termsCheck: false,
            errMsg: '',
            itemData: {},
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setInitData();
        openTerms = this.openTerms;
    },
    mounted() {
        EventBus.$on('setMemberKey', this.setCurationKey)
    },
    beforeDestroy() {
        EventBus.$off('setMemberKey')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setInitData() {
            this.curatorKey = this.$route.params.mb_no;
            // this.curationWriteSetData('curatorKey', this.curatorKey);
        },
        setCurationKey(mb_no) {
            this.curatorKey = mb_no;
            this.curationWriteSetData('curatorKey', this.curatorKey);
        },
        openTerms() {
            this.$modal.show('terms-modal', {termsType: 'curation'});
        },
        changeCheck() {
            this.errMsg = "";
        },
        setSelectItem(item) {
            if(util.isEmpty(item.i_idx)) {
                return false;
            }
            this.itemData = item;
            this.i_idx = item.i_idx;
            this.curationWriteSetData('i_idx', this.i_idx);
            this.checkValidator();

        },
        requestConfirm() {
            this.createConfirm({
                content: `'<span class="subject">${this.itemData.i_name}</span>'<br>${this.$t('curation_item_confirm')}`,
                confirm: () => {
                    EventBus.$emit('curationWrite')
                }
            })
        },
        requestResult() {
            this.createConfirm({
                content : this.$t('curation_c_msg3') +'<br/>'+ this.$t('check_my') ,
                confirmText : this.$t('check_now'),
                cancelText : this.$t('check_later'),
                confirm: () => {
                },
                cancel: () => {

                }
            });
        },
        checkValidator() {
            if(!this.termsCheck) {
                this.errorAlert(this.$t('curation_create_err2'))
                return false;
            }
            //"ibc_status": null, //0:대기,1:완료,2:거절,3:의뢰취소,4:판매완료,5:의뢰포기
            let itemStatus = [0, 1, 4];
            if(itemStatus.indexOf(this.itemData.ibc_status) > -1) {
                this.errorAlert(this.$t('curation_create_err3'));
                return false;
            }
            this.requestConfirm();
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
